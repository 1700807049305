// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/common.json';  // Đường dẫn tới file ngôn ngữ tiếng Anh
import vi from './locales/vi/common.json';  // Đường dẫn tới file ngôn ngữ tiếng Việt

// Khởi tạo i18next
i18n.use(initReactI18next) // Liên kết i18next với react-i18next
  .init({
    resources: {
      en: { translation: en },
      vi: { translation: vi },
    },
    lng: 'en', // Ngôn ngữ mặc định
    fallbackLng: 'en', // Ngôn ngữ thay thế nếu ngôn ngữ hiện tại không tìm thấy
    interpolation: {
      escapeValue: false, // React đã xử lý việc escape các giá trị
    },
  });

export default i18n;
