/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.css"
import React from "react"
import { AppProviders } from "./src/components/app-providers"
import "@fontsource/lato"
import { I18nextProvider } from "react-i18next";
import i18n from "./src/i18n";

export const wrapRootElement = ({ element }) => (
  <I18nextProvider i18n={i18n}>
    <AppProviders>{element}</AppProviders>
  </I18nextProvider>
)
